.signUpRequired-container {
  margin-top: -10px;
  margin-bottom: 50px;
  width: 100%;
}

.signUpRequired-card {
  margin: 0 auto 0 auto;
  background-color: white;
  border: 1px solid #ffbd00;
  border-radius: 5px;
  padding: 40px;
  width: 100%;
  text-align: center;
}

.signUpRequired-header {
  color: dark gray;
  font-size: 2.2em;
}

.signUpRequired-description {
  font-size: 1.1em;
  font-weight: 400;
  margin: 0 0 20px 0;
  color: dark gray;
}

@media (max-width: 600px) {
  .signUpRequired-card {
    padding: 40px 20px 40px 20px;
  }
}
